import { __decorate } from "tslib";
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { financeiroParcelaStore } from '@/utils/store-accessor';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import _ from 'lodash';
let ReajustarMaterialParcelamentoComponent = class ReajustarMaterialParcelamentoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.dataAtual = new Date();
        this.items = [];
        this.itemsSelected = [];
        this.selectedYear = this.dataAtual.getFullYear();
        this.loading = false;
        this.selectAll = false;
        this.headers = [
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Parcela', value: 'parcela_numero', width: '10px' },
            { text: 'Descrição', value: 'financeiro_observacao' },
            { text: 'Valor integral atual (antes ajuste)', value: 'valor_integral' },
            { text: 'Valor integral após ajuste', value: 'valor_apos_ajuste' },
            { text: 'Percentual desconto', value: 'desconto_percentual' },
            { text: 'Valor com desconto', value: 'valor_original' },
            { text: 'Vencimento', value: 'vencimento' },
        ];
    }
    onCancel() {
        this.dialog = false;
        this.selectedYear = this.dataAtual.getFullYear();
        this.$emit('on-cancel');
    }
    async onConfirm() {
        this.loading = true;
        // usar await no this.$emit não funciona, pois o emit não tem ligação direta com a função
        // await this.$emit('on-confirm');
        // @ts-ignore
        await this.$parent.saveParcelamentoMaterialReajuste();
        // em todos os selecionados o valor_integral deve ser substituido pelo valor_apos_ajuste
        this.itemsSelected.forEach((item) => {
            const itemDoArrayPrincipal = _.find(this.items, (i) => i.id == item.id);
            itemDoArrayPrincipal.valor_integral = item.valor_apos_ajuste;
        });
        // calcula o valor_integral do financeiro
        this.itemsSelected.forEach((item) => {
            const total = _(this.items)
                .filter((i) => i.financeiro_id == item.financeiro_id)
                .sumBy('valor_integral');
            item.financeiro_valor_integral = total;
        });
        const items = this.itemsSelected.map((item) => {
            return {
                id: item.id,
                financeiro_id: item.financeiro_id,
                financeiro_numero_parcelas: item.financeiro_numero_parcelas,
                desconto_percentual: item.desconto_percentual,
                valor_integral: item.valor_apos_ajuste,
                contrato_id: item.contrato_id,
                financeiro_parcela_status_id: item.financeiro_parcela_status_id,
                financeiro_categoria_id: item.financeiro_categoria_id,
                financeiro_valor_integral: item.financeiro_valor_integral,
            };
        });
        setTimeout(async () => {
            await financeiroParcelaStore.updateFinanceiroVencimentoEmLote(items);
            this.selectedYear = this.dataAtual.getFullYear();
            this.$swal({
                // @ts-ignore
                title: `Atualização efetuada com sucesso`,
                text: `Foram atualizadas ${this.itemsSelected.length} parcelas`,
                icon: 'success',
            });
            this.itemsSelected = [];
            this.dialog = false;
            this.loading = false;
        }, 1000);
    }
    async onChangeSelectedYear(val) {
        if (val.length === 4) {
            this.loading = true;
            this.items = await financeiroParcelaStore.getFinanceirosParcelas(this.selectedYear);
            this.items.forEach((item) => (item.valor_apos_ajuste = this.calculaValorParcelaAjustado(item)));
            this.loading = false;
        }
    }
    calculaValorParcelaAjustado(item) {
        const numeroParcelaValor = _.find(this.propNumeroParcelasValor, (val) => val.numero_vezes === item.financeiro_numero_parcelas);
        const valorIntegral = numeroParcelaValor.valor_integral;
        const descontoPercentual = valorIntegral * (item.desconto_percentual / 100);
        const vezes = numeroParcelaValor.numero_vezes;
        return (valorIntegral - descontoPercentual) / vezes;
    }
};
__decorate([
    Prop({ default: null })
], ReajustarMaterialParcelamentoComponent.prototype, "propNumeroParcelasValor", void 0);
__decorate([
    Watch('selectedYear')
], ReajustarMaterialParcelamentoComponent.prototype, "onChangeSelectedYear", null);
ReajustarMaterialParcelamentoComponent = __decorate([
    Component({
        components: {
            EntidadeLinkComponent,
        },
    })
], ReajustarMaterialParcelamentoComponent);
export default ReajustarMaterialParcelamentoComponent;
