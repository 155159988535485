import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { financeiroCategoriaStore } from '@/utils/store-accessor';
import ShowPopup from '@/components/ShowPopup.vue';
let FinanceiroCategoria = class FinanceiroCategoria extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Descrição', value: 'nome' },
            { text: 'Tipo', value: 'tipo' },
        ];
        this.dialogCadastrarFinanceiroCategoriaShow = false;
        this.dialogCadastrarFinanceiroCategoriaPaiItem = null;
        this.dialogCadastrarFinanceiroCategoriaNome = '';
        this.categoryEditName = '';
        this.dialogEditFinanceiroCategoriaItemObj = { name: '', id_fc: 0 };
        this.loading = true;
        this.dialogEditFinanceiroCategoriaItem = false;
        this.isEditing = false;
        this.isLoadingRestaurarPadrao = false;
    }
    get items() {
        return financeiroCategoriaStore.FinanceiroCategoriasTreeview;
    }
    showDialogCadastro(item) {
        this.isEditing = false;
        this.dialogCadastrarFinanceiroCategoriaShow = true;
        this.dialogCadastrarFinanceiroCategoriaPaiItem = {
            id: item.id,
            id_fc: item.id_fc,
            name: item.name,
            classificacao: item.classificacao,
            chave: item.chave,
        };
    }
    showDialogEdit(item) {
        this.isEditing = true;
        this.dialogEditFinanceiroCategoriaItem = true;
        this.categoryEditName = item.name;
        this.dialogEditFinanceiroCategoriaItemObj = { ...item };
    }
    onCancel() {
        this.dialogCadastrarFinanceiroCategoriaShow = false;
        this.dialogEditFinanceiroCategoriaItem = false;
        this.dialogCadastrarFinanceiroCategoriaPaiItem = null;
        this.dialogCadastrarFinanceiroCategoriaNome = null;
    }
    async onConfirm() {
        if (!this.isEditing) {
            const obj = {
                nome: this.dialogCadastrarFinanceiroCategoriaNome,
                id_fc: this.dialogCadastrarFinanceiroCategoriaPaiItem.id_fc,
            };
            await financeiroCategoriaStore.createFinanceiroCategorias(obj);
            await financeiroCategoriaStore.getFinanceiroCategoriasTreeview();
        }
        else {
            const obj = {
                nome: this.categoryEditName,
                id_fc: this.dialogEditFinanceiroCategoriaItemObj.id_fc,
            };
            this.dialogEditFinanceiroCategoriaItemObj.name = this.categoryEditName;
            await financeiroCategoriaStore.updateFinanceiroCategoria(obj);
            await financeiroCategoriaStore.getFinanceiroCategoriasTreeview();
        }
        this.onCancel();
    }
    async onDeleteItem(item) {
        this.$swal({
            title: 'Confirma a exclusão da categoria de financeiro?',
            text: 'Após exclusão a categoria de financeiro não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await financeiroCategoriaStore.deleteFinanceiroCategoria(item.id_fc);
                await financeiroCategoriaStore.getFinanceiroCategoriasTreeview();
                this.loading = false;
            }
        });
    }
    async restaurarPadrao() {
        this.$swal({
            title: 'Confirma a restauração dos padrões da categoria de financeiro?',
            text: 'Após a confirmação esta operação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.isLoadingRestaurarPadrao = true;
                await financeiroCategoriaStore.financeiroCategoriaRestaurarPadrao('categoria_financeiro');
                this.isLoadingRestaurarPadrao = false;
            }
        });
    }
    async mounted() {
        await financeiroCategoriaStore.getFinanceiroCategoriasTreeview();
    }
};
FinanceiroCategoria = __decorate([
    Component({
        components: {
            ShowPopup,
        },
    })
], FinanceiroCategoria);
export default FinanceiroCategoria;
