var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"90%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Reajustar parcelas de material didático")]),_c('v-card-subtitle',[_vm._v(" Nesta tela você consegue ajustar o valor do material didático dos alunos, essa operação deve ser efetuada na mudança de ano após o valor do livro sofrer reajuste"),_c('br'),_vm._v(" Antes de prosseguir com o reajuste confime que os valores de material didático estão atualizados na tela anterior ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"type":"number","label":"Financeiros que tem o vencimento da primeira parcela no ano de"},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1)],1),_c('span',[_vm._v("Selecione quais financeiros você deseja atualizar")]),_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions:[-1]},"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"dense":"","fixed-header":"","hide-default-footer":"","show-select":"","height":"calc(80vh - 200px)","sort-by.sync":"aluno_nome"},scopedSlots:_vm._u([{key:"item.checked",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":""},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}},{key:"item.parcela_numero",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parcela_numero)),(item.financeiro_numero_parcelas)?_c('span',[_vm._v("/"+_vm._s(item.financeiro_numero_parcelas))]):_vm._e()]}},{key:"item.valor_integral",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]}},{key:"item.valor_apos_ajuste",fn:function(ref){
var item = ref.item;
return [(item.valor_integral != item.valor_apos_ajuste)?_c('div',[_c('v-chip',{staticClass:"error",attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("formatPriceValue")(item.valor_apos_ajuste)))]),_c('span',{staticClass:"d-flex flex-column",staticStyle:{"font-size":"0.7em","color":"red"}},[_vm._v(" Pendente atualização ")])],1):_c('div',[_c('v-chip',{staticClass:"success",attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("formatPriceValue")(item.valor_apos_ajuste)))])],1)]}},{key:"item.valor_atualizado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_atualizado))+" ")]}},{key:"item.vencimento",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('entidade-link-component',{attrs:{"propEntidadeId":item.aluno_id,"propEntidadeNome":item.aluno_nome,"propEntidadeTipo":"aluno"}})]}},{key:"item.valor_original",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_original))+" ")]}}],null,true),model:{value:(_vm.itemsSelected),callback:function ($$v) {_vm.itemsSelected=$$v},expression:"itemsSelected"}}),_c('span',[_vm._v("Total de títulos selecionadas: "+_vm._s(_vm.itemsSelected.length)+" ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.onCancel}},[_vm._v("Voltar")]),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.itemsSelected.length <= 0 || _vm.loading},on:{"click":_vm.onConfirm}},[_vm._v("Sim, eu confirmo o reajuste das parcelas")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }